'use client';

import { LicenseInfo } from '@mui/x-license-pro';

const MUI_PRO_LICENSE_KEY = process.env.NEXT_PUBLIC_MUI_PRO_KEY;

if (MUI_PRO_LICENSE_KEY) {
	LicenseInfo.setLicenseKey(MUI_PRO_LICENSE_KEY);
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export default function MuiXLicense() {
	return null;
}
