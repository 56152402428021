'use client';

import React from 'react';
import { pathToRegexp } from 'path-to-regexp';
// @mui
import { Box, Backdrop, Typography } from '@mui/material';
// @next
import { usePathname, useRouter } from 'next/navigation';
// @app
import { useStore } from '@app/store';
// ui
import { CircularProgress } from 'hohm-ui';
import { useShallow } from 'zustand/react/shallow';

const safeRoutes = [
	'/',
	'/login/:nextRoute*',
	'/forgot-password',
	'/update-password/:token',
	'/password-reset/:token',
	'/verify-account/:token',
	'/not-found/:nextRoute*',
	'/forgot-password',
	'/unsubscribe/:token',
	'/terms-and-conditions',
].map((path) => pathToRegexp(path));

const protectedRoutes = ['/access-finance'].map((path) => pathToRegexp(path));

const AuthCheck = ({ children }: { children: React.ReactElement }) => {
	const router = useRouter();
	const currentRoute = usePathname();

	const isBrowser = typeof window !== 'undefined';

	const { accessToken, userId, permissions } = useStore(useShallow(state => ({
		accessToken: state.accessToken,
		userId: state.userId,
		permissions: state.permissions,
	})));

	const hasAccess = () => {
		if (safeRoutes.some((checkRoute) => checkRoute.test(currentRoute))) {
			return true;
		}

		if (
			userId &&
			accessToken &&
			protectedRoutes.some((checkRoute) => checkRoute.test(currentRoute))
		) {
			if ((permissions || []).includes('isChannelPartner')) {
				return true;
			}
		}

		return false;
	};

	const canAccess = hasAccess();

	if (isBrowser && canAccess === false) router.push('/login');

	// a loading component that prevents the page from rendering
	if (canAccess === false)
		return (
			<Backdrop open>
				<Box
					sx={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						flexDirection: 'column',
					}}
				>
					<CircularProgress sx={{ mb: 2 }} />
					<Typography
						variant="overline"
						color="white"
						sx={{ color: '#fff' }}
					>
						Authenticating...
					</Typography>
				</Box>
			</Backdrop>
		);

	return children;
};

export default AuthCheck;
