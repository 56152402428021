'use client';

import { QueryClient, QueryClientProvider } from 'react-query';
import { PropsWithChildren } from 'react';

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			refetchOnWindowFocus: false,
			// 5 * 1000
			staleTime: 60000,
		},
	},
});

const ReactQueryProvider: React.FC<PropsWithChildren> = ({ children }) => {
	// Return Provider
	return (
		<QueryClientProvider client={queryClient}>
			{children}
		</QueryClientProvider>
	);
};

export default ReactQueryProvider;
