'use client';

import { Authenticate } from 'hohm-auth';
import { PropsWithChildren } from 'react';
import { auth } from '../client';

const AuthenticateApp: React.FC<PropsWithChildren> = ({ children }) => {
	return (
		<Authenticate reAuthenticateClient={auth.reAuthenticateClient}>
			<>
				<div />
				{children}
			</>
		</Authenticate>
	);
};

export default AuthenticateApp;
